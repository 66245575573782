.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  
  label {
    margin-bottom: 10px;
  }
  
  select {
    width: 200px;
    padding: 10px;
    margin-bottom: 10px;
    border: none;
    border-radius: 5px;
    background-color: #f5f5f5;
  }

/* CSS for responsiveness */
.main-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Adjust font size and spacing for mobile */
@media screen and (max-width: 767px) {
  body {
    font-size: 14px;
  }

  /* Adjust column size for smaller screens */
  .react-bootstrap-table .table {
    width: 100%;
  }
  .react-bootstrap-table .row .col-md-6 {
    flex-basis: 100%;
  }
  /* Add more styles for better mobile view */
  /* ... (additional styles) */
}