.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  .error-message {
    color: red;
    margin-top: 10px;
  }
  
  .login-form {
    width: 300px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f9f9f9;
  }
  
  .login-form h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .form-group input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  button[type='submit'] {
    width: 100%;
    padding: 8px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  @media screen and (max-width: 480px) {
    .login-form {
      width: 100%;
    }
  }
  